<template>
    
    <v-container
        width="100%"
        fill-height
    >
        <l-map
            style="z-index:1;"
            float
            height="100%"
            width="100%"
            :bounds="bounds"
            @update:zoom="zoomUpdated"
            @update:bounds="boundsUpdated"
        >
            <l-tile-layer :url="mapUrl" :attribution="attribution"> </l-tile-layer>
            
            <!-- NAVIGATION ELEMENTS -->
            <l-control position="bottomleft" >
                <v-btn @click="setBounds">
                    <v-icon>mdi-fit-to-page-outline</v-icon>
                    show entire route
                </v-btn>
            </l-control>

            <!-- location markers -->
            <l-marker
                v-for="l in allLocations"
                :key="l.key"
                :lat-lng="[l.lat, l.lon]"
                :draggable="false"
            >
                <l-popup
                    class="pa-0"
                    :options="{'max-width': '600', 'min-width': '300'}"
                >
                    <v-card
                        flat
                        min-width="300"
                    >
                        <v-card-title>
                            {{l.name}}
                        </v-card-title>
                        <v-divider />
                        <!-- ARTICLES -->
                        <v-list
                            dense
                        >
                            <!-- <v-subheader> Articles </v-subheader> -->
                            <v-list-item 
                                v-for="a in articlesOfLocation(l.key)"
                                :key="a.key"
                                :to="{name: 'BlogEntry', params: {blogID: a.key}}"
                            >
                                <!-- <v-list-item-icon>
                                    <v-icon>mdi-message-text</v-icon>
                                </v-list-item-icon> -->
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{a.title}} - {{a.date}}
                                    </v-list-item-title>
                        
                                    <!-- pictures -->
                                    <v-list-item-subtitle
                                        v-if="picturesOfArticle(a.key).length"
                                    >
                                        <v-row>
                                            <v-col 
                                                v-for="i in picturesOfArticle(a.key)"
                                                :key="i.key"
                                                cols="3"
                                                class="d-flex child-flex pa-1 ma-1"
                                                >
                                                <v-img
                                                    :src="i.thumbUrl"
                                                    :alt="i.pictext"
                                                    aspect-ratio="1"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        
                        <v-divider />

                    </v-card>
                </l-popup>
            </l-marker>

            <!-- travel route -->
            <!-- <l-polyline 
                v-if="showRoute"
                :lat-lngs="routePath" 
                color="red"
            >
            </l-polyline> -->

            <l-polyline
                v-for="i in routePathPoints.length-1"
                :key="i"
                :lat-lngs="[routePath[i-1], routePath[i]]"
                :color="gradient[i-1]"
            >
            </l-polyline>
        </l-map>
    </v-container>

</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup, LPolyline, LControl } from "vue2-leaflet";
import { mapGetters } from 'vuex';
import utils from "../utils.js"
// import L from "leaflet";

export default {
    name: 'mapView',
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LPolyline,
        LControl,
    },
    data() {
        return {
            toDelete: null,

            //// MAP PROPERTIES
            watercolorMap: true, // switches between Stamen watercolor tiles and original OSM tiles
            showRoute: true, // turns off route plotting between locations
             // MAPDATA
            zoom: 12,
            center: [47.41322, -1.219482],
            bounds: null,
            markerLatLng: [47.31322, -1.319482],
            gtb: {
            // Gothenburg, SE
            lat: 57.7089,
            lon: 11.9746,
            //////////////////
            
        },
        }
    },
    methods: {
        // MAP METHODS
        zoomUpdated(zoom) {
            this.zoom = zoom;
        },
        centerUpdated(center) {
            this.center = center;
        },
        boundsUpdated(bounds) {
            this.bounds = bounds;
        },
        setBounds() {
            this.bounds = this.mapBounds
        },
        // DELETION:
        
        deleteLocation() {
            console.log("deleting: " + this.toDelete)
            this.$store.dispatch('deleteLocation', {locationKey: this.toDelete.key})
        },
    },
    computed: {
        ...mapGetters([
            'allLocations', 
            // 'picturesOfLocation', 
            'articlesOfLocation',
            'routePath',
            'picturesOfArticle',
            ]),
        mapUrl() {
            if(this.watercolorMap) {
                return "https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.png"
            } else {
                return 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            }
        },
      
        attribution() {
            if (this.watercolorMap) {
                return "Map tiles by Stamen Design, under CC BY 3.0. Data by OpenStreetMap, under CC BY SA" 
            } else {
                return "map tiles by Open Street Map"
            } 
        },
        mapBounds() {
            var top = this.gtb.lat + 1
            var bottom = this.gtb.lat - 1
            var left = this.gtb.lon - 1
            var right = this.gtb.lon + 1

            for (const l of this.allLocations) {
                if (l.lat > top) {
                    top = l.lat
                } else if (l.lat < bottom) {
                    bottom = l.lat
                }
                if (l.lon < left) {
                    left = l.lon
                } else if (l.lon > right) {
                    right = l.lon
                }
            }

            let bounds = [
                [top, left],
                [bottom, right]
            ]

            return bounds
        },
        gradient() {
            let gradient = utils.lineGradient(this.routePath.length)
            console.log('gradient')
            console.log(gradient)
            return gradient

        },
        routePathPoints() {
            if (this.showRoute) {
                console.log('routepath')
                console.log(this.routePath)
                return this.routePath
            } else {
                return [null]
            }
        },
        picturesOfArticleLimitFive(articleID) {
            // returns the first 5 pics of an article
            return this.picturesOfArticle(articleID).slice[0, 5]
        },
    },
    mounted() {
        this.setBounds()
    }
}
</script>