// Returns a single rgb color interpolation between given rgb color
// based on the factor given; via https://codepen.io/njmcode/pen/axoyD?editors=0010
function interpolateColor(color1, color2, factor) {
    if (arguments.length < 3) { 
        factor = 0.5; 
    }
    var result = color1.slice();
    for (var i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
}
// My function to interpolate between two colors completely, returning an array
function interpolateColors(color1, color2, steps) {
    // takes string "rgb(94, 79, 162)", "rgb(94, 79, 162)" and int for steps
    // returns list ["rgb(94, 79, 162)", "rgb(94, 79, 162)", ...]

    var stepFactor = 1 / (steps - 1),
        interpolatedColorArray = [];

    // color1 = color1.match(/\d+/g).map(Number);
    // color2 = color2.match(/\d+/g).map(Number);

    for(var i = 0; i < steps; i++) {
        interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i));
    }

    return interpolatedColorArray;
}

function hexToRgb(hex) {
    // takes string "#ffffff"
    // returns 
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    
    const r = parseInt(result[1], 16)
    const g = parseInt(result[2], 16)
    const b = parseInt(result[3], 16)
     
    // to return {r: 000, g: 000, b:000}
    // return result ? {
    //   r: parseInt(result[1], 16),
    //   g: parseInt(result[2], 16),
    //   b: parseInt(result[3], 16)
    // } : null;
    //return "rgb(" + String(r) + ", " + String(g) + ", " + String(b) + ")"
    return [r, g, b]
}
  


function componentToHex(c) {
    // from https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  
  function rgbToHex(rgbArray) {
    let r = rgbArray[0]
    let g = rgbArray[1]
    let b = rgbArray[2]
    
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

//   function rgbStrToHex(rgbString) {
//     let rgb = rgbString.match(/\d+/g).map(Number);
//     return rgbToHex(rgb[0], rgb[1], rgb[2])
//   }
  

  export default {
      name: 'utils',
      lineGradient: function (n) {
          // returns a list of hex colors evenly from grey to red for the map line
        // n steps
        const fromColor = "#ff0000" //red
        const toColor = "#0000ff"   // grey

        const fromColorRGB = hexToRgb(fromColor)
        const toColorRGB = hexToRgb(toColor)

        const RGBcolors = interpolateColors(fromColorRGB, toColorRGB, n-2)
        
        let hexColors = []
        hexColors.push(fromColor)
        for (let c of RGBcolors) {
            hexColors.push(rgbToHex(c))
        }
        
        hexColors.push(toColor)

        return hexColors

      },
  }